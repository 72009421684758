import React from 'react';
import {
  ComponentPreview,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../components';
import pageHeroData from '../../../data/pages/patterns.yml';
import userlistsPreviewData from '../../../data/previews/user-lists.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      styleSelect: 'large',
    };
  }

  handleScaleChange = e => {
    this.setState({
      styleSelect: e,
    });
  };

  render() {
    return (
      <PageWithSubNav pageType="design" title="User Lists" subnav="patterns">
        <PageHero heroData={pageHeroData} tierTwo="User Lists" hideHeader />
        <PageNavigation links={['Scale', 'Usage', 'Microcopy']} />

        <Section title="Scale">
          <Paragraph>
            When choosing the{' '}
            <Link href="/components/avatars/design">avatar</Link> size for each
            user list, consider the interface as a whole and how it will fit in.
            Stick with small, medium and large.
          </Paragraph>
          <ComponentPreview
            name="PatternsContainer"
            layout="fullWidth"
            hideEnvironmentToggle
            previewData={userlistsPreviewData.scale}
            onChange={this.handleScaleChange}>
            {this.state.styleSelect === 'large' && (
              <img
                src={require('../../../imgs/previews/preview-user-lists-scale-large.png')}
              />
            )}
            {this.state.styleSelect === 'medium' && (
              <img
                src={require('../../../imgs/previews/preview-user-lists-scale-medium.png')}
              />
            )}
            {this.state.styleSelect === 'small' && (
              <img
                src={require('../../../imgs/previews/preview-user-lists-scale-small.png')}
              />
            )}
          </ComponentPreview>
        </Section>
        <Section title="Usage">
          <SectionSubhead>List Items</SectionSubhead>
          <Paragraph>
            Regardless if the list will display one user or more than fifty,
            each item should be identical. In some cases the list may grow over
            time, like when a user joins another team. There is no minimum list
            requirement to use this pattern, but be wise when displaying long
            lists.
          </Paragraph>
          <DontDo
            dontText="force the user to scroll through infinite lists to find who they’re looking for."
            doText="use search and scrolling to easily find people."
            imgFilename="user-list-items"
          />
          <SectionSubhead>Layout</SectionSubhead>
          <Paragraph>
            User lists should always be displayed vertically. Vertical space is
            abundant on both the web and mobile application, and scrolling is
            second nature. Don’t go against the grain and display user lists in
            unexpected layouts.
          </Paragraph>
          <DontDo
            dontText="format user lists in multi-column layouts."
            doText="divide items in long lists."
            imgFilename="user-list-column"
          />
        </Section>
        <Section title="Microcopy">
          <SectionSubhead>Hierarchy</SectionSubhead>
          <Paragraph>
            Each item in a user list should repeat the information in the same
            format, in proper hierarchy. Primary information should always
            appear first, then secondary, and finally supporting, nonessential
            information (if applicable).
          </Paragraph>
          <DontDo
            dontText="organize content in a random order."
            doText="use nonessential text to support the information hierarchy."
            imgFilename="user-list-hierarchy"
          />
          <SectionSubhead>Limit Information</SectionSubhead>
          <Paragraph>
            Depending on the context of the list, it’s easy to accidentally cram
            in a lot of relevant information. Be intentional with what is
            displayed and choose to limit to the most important pieces of
            content.
          </Paragraph>
          <DontDo
            dontText="overload the list item with all available content."
            doText="pick and choose the most important pieces."
            imgFilename="user-list-limit-info"
          />
          <SectionSubhead>Content and Color</SectionSubhead>
          <Paragraph>
            Use <Link href="/components/type/text/design">text</Link> for all
            content in a user list. Make use of small and micro sizes, both in
            default and bold weight. To further emphasize each piece of
            information, stick to using Contrast, Default and Nonessential{' '}
            <Link href="/guidelines/colors/content/design">content colors</Link>
            .
          </Paragraph>
          <DontDo
            dontText="mix and match text sizes."
            doText="follow a consistent format for each."
            imgFilename="user-list-content-mix"
          />
          <DontDo
            dontText="use one color for all elements in a list."
            doText="use content hierarchy to inform color choice."
            imgFilename="user-list-one-color"
          />
          <DontDo
            dontText="switch up content that the user can interact with."
            doText="keep interactions between items orderly."
            imgFilename="user-list-interaction"
          />
        </Section>
      </PageWithSubNav>
    );
  }
}
